import { Collapse } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Badge, ExpandLessIcon, ExpandMoreIcon, Item, Title } from './styledComponents';
import { SidebarNavListItemProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = ({
  title,
  href,
  depth = 0,
  children,
  icon: Icon,
  badge,
  bigMenu,
  onClose
}: SidebarNavListItemProps) => {
  const { t: translate } = useTranslation();

  const [open, setOpen] = React.useState(true);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <React.Fragment>
        <Item onClick={handleToggle}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {bigMenu && translate(title)}
            {badge && <Badge label={badge} />}
          </Title>
          {
            bigMenu && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)
          }
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }


  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href === 'contact' ? '/' : href}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={(e)=>{
          if (href === 'contact') {
            e.stopPropagation()
            e.preventDefault()
            window.open('https://support.backuplabs.io/hc/en-gb', '_blank')
          }
          if (onClose) onClose()
        }}
        activeclassname={href === 'contact' ? '' : 'active'}
        bigmenu={bigMenu.toString()}
      >
        {Icon && <Icon />}
        {bigMenu && (
          <Title depth={depth}>
            {bigMenu && translate(title)}
            {badge && bigMenu && <Badge label={badge} />}
          </Title>
        )}
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
