import {
  IGetterBoard,
  IGetterProject, IGetterRepo,
  IGetterTrelloUser,
  IGetterNotionUser,
  IGetterUserGithub,
  IGetterUserGitlab,
  IGithubUser,
  IGitlabUser,
  IItem,
  IJiraUser, IGetterWorkspace,
} from '../models/models';
import { humanReadableFileSize } from '../utils/fileSizeConverter';
import {EActionStatus, ERepoBoardStatus, JiraProjectsTypeKeyTemplates} from "../models/consts";

export function addPlatformIdentifier(actionName, platformName) {
  return actionName + '-' + String(platformName).toLowerCase();
}

export function githubRepoAdapterFunc(payload) {
  return (payload.repos || payload.gists || [] as Array<IGetterRepo>).reduce((acc, { id, name, is_daily_backed_up, action_process, last_backup_date, size, isActive, is_private, status, type, description, public:gistPublic }) => {
    acc.push({
      id: id,
      repo: name + (is_private === undefined && gistPublic === undefined ? '' : is_private || (!gistPublic && type === 'gist') ? '::PVT' : '::PBC'),
      user: payload.github_user_name,
      lastBackup: last_backup_date ? new Date(last_backup_date).toLocaleString() : '-',
      size: humanReadableFileSize(size),
      sizeBytes: size,
      isActive: isActive === undefined ? true : isActive,
      isDailyActive: is_daily_backed_up === undefined ? true : is_daily_backed_up,
      status: status === undefined ? ERepoBoardStatus.INITIALIZED : status,
      isRepo: payload.isRepo !== undefined ? payload.isRepo : type !== 'gist',
      description: description || '',
      actionProcess: !!action_process?.length ? action_process : ''
    });

    return acc;
  }, [] as Array<IItem>);
}

export function githubUserAdapterFunction(payload): Array<IGithubUser> {
  return (payload as Array<IGetterUserGithub>)
    .map(({
      username,
      auto_add,
      githubapp,
      user_type,
      githubapp_owner,
      owner,
      is_confirmed,
      id,
      owner_id
    }) => ({
      id: id || 111 ,
      username,
      auto_add,
      githubapp,
      userType: user_type,
      ownerId: owner_id,
      githubAppOwner: githubapp_owner,
      owner,
      isConfirmed: is_confirmed
    }));
}

export function trelloUserAdapterFunction(payload): Array<IGitlabUser> {
  return (payload as Array<IGetterTrelloUser>)
    .map(({ user_name, full_name }) => ({ username: user_name, fullName: full_name }));
}

export function notionUserAdapterFunction(payload): Array<IGitlabUser> {
  return (payload as Array<IGetterNotionUser>)
    .map(({name,ownerId ,type, email}) => {
      return ({username: name,ownerId: ownerId,userType:type, email})
    });
}

export function notionWorkspaceAdapterFunc(payload) {
  return (payload as Array<IGetterWorkspace>).reduce((acc, { id, name, status, is_daily_backed_up, isActive, last_backup_date, size, action_process}) => {
    acc.push({
      id: id,
      workspace: name,
      lastBackup: last_backup_date ? new Date(last_backup_date).toLocaleString() : '-',
      isActive:  isActive,
      isDailyActive: is_daily_backed_up,
      size: humanReadableFileSize(size),
      sizeBytes: size,
      status: status === undefined ? ERepoBoardStatus.INITIALIZED : status,
      actionProcess: !!action_process?.length ? action_process : ''
    } as IItem);


    return acc;
  }, [] as Array<IItem>);
}

export function trelloBoardAdapterFunc(payload) {
  return (payload as Array<IGetterBoard>).reduce((acc, { id, name, last_backup_date, action_process, is_daily_backed_up, size, permissionLevel, organizationName, status, isActive}) => {
    let permLevel = '';
    if (permissionLevel) {
      switch (permissionLevel.toLowerCase()) {
        case 'private':
          permLevel += 'PVT'
          break;
        case 'public':
          permLevel += 'PBC'
          break;
        case 'workspace':
          permLevel += 'WKS'
          break;
        case 'org':
          permLevel += 'WRK / ORG';
          break;
        default:
          permLevel += permissionLevel;
          break;
      }
      if (organizationName) {
        permLevel += (' > ' + organizationName)
      }
    }
    acc.push({
      id: id,
      board: name + (permLevel ? `::${permLevel.toUpperCase()} ` : ''),
      lastBackup: last_backup_date ? new Date(last_backup_date).toLocaleString() : '-',
      size: humanReadableFileSize(size),
      sizeBytes: size,
      isActive: isActive === undefined ? true : isActive,
      isDailyActive: is_daily_backed_up === undefined ? true : is_daily_backed_up,
      status: status === undefined ? ERepoBoardStatus.INITIALIZED : status,
      actionProcess: !!action_process?.length ? action_process : ''
    } as IItem);


    return acc;
  }, [] as Array<IItem>);
}

export function gitlabRepoAdapterFunc(payload) {
  return (payload as Array<IGetterProject>).reduce((acc, { id, name, last_backup_date, action_process, is_daily_backed_up, size, isActive, status, visibility, type }) => {
    acc.push({
      id: id,
      project: name + (visibility === undefined ? '' : visibility === 'public' ? '::PBC' : visibility === 'private' ? '::PVT' : visibility),
      lastBackup: last_backup_date ? new Date(last_backup_date).toLocaleString() : '-',
      size: humanReadableFileSize(size),
      sizeBytes: size,
      isActive: isActive === undefined ? true : isActive,
      isDailyActive: is_daily_backed_up === undefined ? true : is_daily_backed_up,
      status: status === undefined ? ERepoBoardStatus.INITIALIZED : status,
      actionProcess: !!action_process?.length ? action_process : '',
      isRepo: type !== 'snippet'
    } as IItem);

    return acc;
  }, [] as Array<IItem>);
}

export function gitlabUserAdapterFunction(payload): Array<IGitlabUser> {
  return (payload as Array<IGetterUserGitlab>)
    .map(({ username, auto_add, gitlabapp }) => ({ username, auto_add, gitlabapp }));
}

export function jiraUserAdapterFunction(payload): Array<IJiraUser> {
  return (payload as Array<IGetterTrelloUser>)
    .map(({ user_name, full_name, authorizationMethod }) => ({ username: user_name, fullName: full_name, authMethod: authorizationMethod }));
}

export function jiraProjectAdapterFunc(payload) {
  return (payload as Array<IGetterBoard>).reduce((acc, { id, name, last_backup_date, action_process, is_daily_backed_up, size, isActive, status, type,projectTypeKey, is_private }) => {

    acc.push({
      id: id,
      project: name + (projectTypeKey ? `::${JiraProjectsTypeKeyTemplates[projectTypeKey]} ${is_private ? 'PVT' : 'PBC'} ` : ''),
      typeManage: projectTypeKey ? `${is_private ? 'PVT' : 'PBC'}` : '',
      projectTypeKey,
      lastBackup: last_backup_date ? new Date(last_backup_date).toLocaleString() : '-',
      size: humanReadableFileSize(size),
      sizeBytes: size,
      isActive: isActive === undefined ? true : isActive,
      isDailyActive: is_daily_backed_up === undefined ? true : is_daily_backed_up,
      status: status === undefined ? ERepoBoardStatus.INITIALIZED : status,
      actionProcess: !!action_process?.length ? action_process : ''
    } as IItem);

    return acc;
  }, [] as Array<IItem>);
}

export function createActionObject(actionData)  {
  const {
    data_size,
    date,
    status,
    error_message,
    id,
    type,
    version,
    extra_fields,
    actionProcess,
    _id,
  } = actionData;
console.log('extra_fields',extra_fields)
  return {
    size: (extra_fields?.fullBackupSize || data_size) ? humanReadableFileSize(extra_fields?.fullBackupSize || data_size) : '-',
    sizeSub: data_size ? humanReadableFileSize(data_size) : '-',
    date: date || '',
    status: status as EActionStatus,
    error_message,
    versionid: id || '',
    key: id || '',
    type: type || '',
    versions: version,
    download_zip_url: extra_fields?.get_zip?.download_zip_url,
    zip_status: extra_fields?.get_zip?.zip_status,
    expire_date_zip_url: extra_fields?.get_zip?.expire_date_zip_url,
    action_process: actionProcess,
    _id
  };
};
