import React, {FunctionComponent} from 'react';
import {Button} from '../../components/StyledComponents';
import {Settings} from "react-feather";

const OnWork: FunctionComponent = () => {

  return (
    <div className='close-for-work'>
      <div className='close-for-work-container'>
        <Settings className='close-for-work-icon'/>
        <div className='close-for-work-text'>
          <div className='close-for-work-title'>
            We&apos;ll be back very soon
          </div>
          <div className='close-for-work-desc'>
            BackupLABS is under construction. <br/> Try it again in a few minutes.
          </div>
          <div className='close-for-work-action'>
            <Button
              variant='contained' color='primary'
              onClick={() => window.location.reload()}
            >
              Try again
            </Button>
            <Button
              variant='contained' color='secondary'
              onClick={() => window.open('https://support.backuplabs.io/hc/en-gb', '_blank')}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default  withRedirectAfterLogout()(OnWork);
export default OnWork;
