import { CircularProgress } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IWithRedirectAfterLogout } from '../models/inner-models';
import { useAppSelector } from '../store/hooks';
import { isAuthorized, loadingUser } from '../store/selectors';
import {iniFrame} from "../serviceWorker";

const withRedirectIfAuth = (to?: string) => (Component: React.FunctionComponent): FunctionComponent<IWithRedirectAfterLogout> => (props) => {
  const navigate = useNavigate();
  const isAuth = useAppSelector(isAuthorized);
  const userLoading = useAppSelector(loadingUser);

  useEffect(() => {
    if (!userLoading && isAuth) {
      let link = '/'
      if (iniFrame()) {
        link = '/platform/dashboard/jira'
      }
      navigate( to || link);
    }
  }, [isAuth, userLoading]);

  return (
    <>
      {
        userLoading ?
          <div className="spinner-wrapper">
            <CircularProgress />
          </div> :
          <Component {...props} />
      }
    </>
  );

};

export default withRedirectIfAuth;
