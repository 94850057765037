import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  CircularProgress, Pagination, Stack,
  Typography
} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
  loadingUser, paymentHistorySelector
} from '../../../store/selectors';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import './style.scss';
import styled from 'styled-components';
import {DataGrid, GridCellParams} from "@mui/x-data-grid";
import {getUserProfileName, getUsersPaymentsHistory} from "../../../store/actions";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {EActionStatus, EPlatformPrices, EProPlatformPrices} from "../../../models/consts";
import {EPaymentStatuses} from "../../../constants";
import {Button} from "../../../components/StyledComponents";
import {ArrowLeft, Download, ExternalLink} from "react-feather";
import {useNavigate, useSearchParams} from "react-router-dom";
import Platform from "../../../models/platforms-data";
import {iframeNewTabMsg, iniFrame} from "../../../serviceWorker";

const Chip = styled(MuiChip)<{ color: string }>`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;

  span {
    height: 20px;
  }

  &.success {
    background-color: #D1FAE5;
    color: #065F46;
  }

  &.error {
    background-color: #FEE2E2;
    color: #991B1B;
  }
`;

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 0;
    box-sizing: border-box;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: '14px',
      lineHeight: '20px',
      border: 'none',
      '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-main': {
        maxHeight: 'calc(100vh - 212px)',
        '@media(min-height: 660px)': {
          maxHeight: 'calc(100vh - 340px)'
        }
      },
      padding: 0
    },
  }),
);

const PaymentHistory: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const userLoading = useAppSelector(loadingUser);
  const paymentHistoryItems = useAppSelector(paymentHistorySelector);
  const [searchParams] = useSearchParams();
  const platformName = searchParams.get('platform');
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profiles, setProfiles] = useState<Record<string, any>>({});
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        const resProf = await dispatch(getUserProfileName()).unwrap()
        if (resProf) {
          setProfiles(resProf)
        }
        if (!paymentHistoryItems) {
          await dispatch(getUsersPaymentsHistory()).unwrap();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).replaceAll('_', ' ');
  }

  const PaginationFooter = () => {
    const listLength = paymentHistoryItems ?
      (platformName ?
        paymentHistoryItems.filter((item) => (item.product_id === EPlatformPrices[platformName] || item.product_id === EProPlatformPrices[platformName])).length :
        paymentHistoryItems.length) : 0
    const itemFrom = (pageSize * page) - pageSize + 1
    const itemTo = (pageSize * page) <= listLength ? (pageSize * page) : listLength
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height='64px'
        px='16px'
      >
        <Typography>{`Showing ${itemFrom} to ${itemTo} of ${listLength}`}</Typography>
        <Pagination
          count={Math.ceil(listLength / pageSize)}
          page={page}
          onChange={(e, v) => setPage(v)}
        />
      </Stack>

    )
  };

  return (
    <div id="payment-page">
      {(userLoading || loadingData) ?
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div> :
        <div className="payment-block">
          <div className="header-block actions-header">
            <div className='actions-header-left' onClick={() => navigate(`/subscriptions`)}>
              <ArrowLeft className="arrow-icon"/>
              {translate(`views.platform_pages.actions_list.backto_sub`)}
            </div>
            <div className='actions-header-right'>
              {platformName && (
                <Button variant="outlined" color="primary"
                        onClick={() => {
                          if (iniFrame()) {
                            iframeNewTabMsg()
                          } else if (!iniFrame() && profiles[platformName]) {
                            switch (platformName) {
                              case 'github':
                                window.open(`https://github.com/${profiles.github[Object.keys(profiles.github)[0]]}`, '_blank')
                                break;
                              case 'gitlab':
                                window.open(`https://gitlab.com/dashboard/projects`, '_blank')
                                break;
                              case 'trello':
                                window.open(`https://trello.com/u/${profiles.trello}/boards`, '_blank')
                                break;
                              case 'jira':
                                window.open(`https://${profiles.jira}.atlassian.net/jira/your-work`, '_blank')
                                break;
                              case 'notion':
                              default:
                                window.open(Platform[platformName].url, '_blank')
                                break
                            }
                          } else {
                            window.open(Platform[platformName].url, '_blank')
                          }
                        }}
                >
                  {translate('common.buttons.visit')} {Platform[platformName].title}
                  <ExternalLink className="feather-icon-in-button icon-ml"/>
                </Button>
              )}
              {platformName && (
                <Button variant="outlined" color="primary" ml={3}
                        onClick={() => {
                          localStorage.setItem('checkout-platform', platformName)
                          navigate('/checkout');
                        }}
                >
                  {translate('subscriptions.edit_sub')}
                  {/*<ExternalLink className="feather-icon-in-button icon-ml"/>*/}
                </Button>
              )}

            </div>
          </div>
          <Typography variant="h3" display="block" mb='28px'>
            {translate('common.default_pages.payment_history_title')}
          </Typography>

          {platformName && (
            <div className='header-block action-page-platform-logo'>
              <img src={Platform[platformName]?.smallLogo} className="platform-logo" loading="lazy"/>
              {Platform[platformName]?.title}
            </div>
          )}
          <Card>
            <CardContent>
              <div className="user-actions-container">
                {!paymentHistoryItems?.length && (
                  <div className="spinner-wrapper">
                    <CircularProgress/>
                  </div>
                )}

                {!!paymentHistoryItems?.length &&
                  <DataGrid
                    autoHeight
                    headerHeight={48}
                    rowHeight={56}
                    pageSize={pageSize}
                    page={page - 1}
                    onPageSizeChange={(e) => setPageSize(e)}
                    className={classes.root}
                    rows={paymentHistoryItems ?
                      (platformName ?
                        paymentHistoryItems.filter((item) => (item.product_id === EPlatformPrices[platformName] || item.product_id === EProPlatformPrices[platformName])) :
                        paymentHistoryItems) :
                      []}
                    columns={[
                      {
                        field: 'created',
                        headerName: translate('subscriptions.payment_history.time'),
                        flex: 1,
                        editable: false,
                        sortable: false,
                        headerClassName: 'subscription-table--header',
                        renderCell: (params: GridCellParams) => (
                          <div style={{fontWeight: 600}}>{new Date(Number(params.value + '000')).toLocaleDateString()}</div>
                        ),
                      },
                      // {
                      //   field: 'invoice_id',
                      //   headerName: translate('subscriptions.payment_history.time'),
                      //   flex: 1,
                      //   editable: false,
                      //   sortable: false,
                      //   headerClassName: 'subscription-table--header',
                      //   renderCell: (params: GridCellParams) => (
                      //     <div>{new Date(Number(params.value + '000')).toLocaleDateString()}</div>
                      //   ),
                      // },
                      {
                        field: 'amount_received',
                        headerName: translate('subscriptions.payment_history.amount'),
                        flex: 1,
                        editable: false,
                        sortable: false,
                        headerClassName: 'subscription-table--header',
                        renderCell: (params: GridCellParams) => (
                          <div style={{fontWeight: 600}}>
                            {(params.value / 100).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD"
                            })}
                            <div className='price-sub'>
                              Incl. taxes and fees
                            </div>
                          </div>
                        ),
                      },
                      {
                        field: 'currency',
                        headerName: translate('subscriptions.payment_history.currency'),
                        flex: 1,
                        editable: false,
                        sortable: false,
                        headerClassName: 'subscription-table--header',
                        renderCell: (params: GridCellParams) => (
                          <div>{String(params.value).toUpperCase()}</div>
                        ),
                      },
                      {
                        field: 'status',
                        headerName: translate('subscriptions.payment_history.status'),
                        flex: 1,
                        minWidth: 120,
                        editable: false,
                        sortable: false,
                        headerClassName: 'subscription-table--header',
                        renderCell: (params: GridCellParams) => (
                          <Chip
                            className={params.value === EActionStatus.SUCCEEDED ? 'success' : 'error'}
                            label={params.value === EActionStatus.SUCCEEDED ? 'success' : params.value}
                          />
                        ),
                      },
                      {
                        field: 'last4',
                        headerName: translate('subscriptions.payment_history.card'),
                        flex: 1,
                        editable: false,
                        sortable: false,
                        headerClassName: 'subscription-table--header',
                        renderCell: (params: GridCellParams) => (
                          params.value ?
                            <div>{'**** ' + params.value}</div> :
                            '-'
                        ),
                      },
                      {
                        field: 'receipt_url',
                        headerName: translate('subscriptions.payment_history.invoice'),
                        minWidth: 180,
                        flex: 1,
                        editable: false,
                        sortable: false,
                        headerClassName: 'subscription-table--header',
                        renderCell: (params: GridCellParams) => {
                          return (
                            <>
                              {params.row.status === EPaymentStatuses.SUCCESS && (
                                <Button mr={2} variant="contained" color="primary"
                                        onClick={() => {
                                          window.open(params.value);
                                        }}>
                                  {translate('subscriptions.payment_history.invoice')}
                                </Button>
                              )}
                              {params.row.invoice_pdf && (
                                <Button mr={2} variant="outlined" color="primary"
                                        onClick={() => {
                                          window.open(params.row.invoice_pdf);
                                        }}>
                                  <Download className='feather-icon-in-button'/>
                                  {translate('subscriptions.payment_history.download_invoice')}
                                </Button>
                              )}
                            </>
                          );
                        },
                      },
                    ]}
                    disableColumnSelector
                    disableSelectionOnClick
                    disableColumnMenu
                    components={{
                      Footer: PaginationFooter
                    }}
                  />
                }
              </div>
            </CardContent>
          </Card>
        </div>
      }
    </div>
  );
};

export default withRedirectAfterLogout()(PaymentHistory);
