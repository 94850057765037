import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import PlatformActionsPageBlock from '../../../../components/PlatformComponents/PlatformActionsPage';
import { EPaymentRequiredType, EPlatformsNames, EStripeSubStatus, ETrialStatus } from '../../../../models/consts';
import {IError, IPaymentRequiredPoppup} from '../../../../models/inner-models';
import {
  downloadGithubGistZip,
  downloadGithubZip,
  getGithubActions,
  restoreGithubBackup, restoreGithubGistBackup
} from '../../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearBackupsState } from '../../../../store/reducers/backups';
import {
  getGithubActionsSelector,
  getGithubActionsTotalSelector,
  getGithubAppInstallSelector,
  getGithubCanRestore,
  getGithubUsersSelector,
  githubSubscription,
  githubTrialStatus,
  isGithubActionsLoadingSelector,
  isSubUser,
  roleByApp,
} from '../../../../store/selectors';
import restore from '../../../../utils/functions/restore';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import { IBackupRow } from '../../types';
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import {errorBigIcon, iconNotifySuccess} from "../../../../assets/icons/icons";

const GithubActivityPage: FunctionComponent = () => {
  const platformName = EPlatformsNames.GITHUB;
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get('id');
  // const name = searchParams.get('name');
  // const type = searchParams.get('type');
  const type = Number(localStorage['githubUser']) === 0 ? 'gist' : 'repo';
  const users = useAppSelector(getGithubUsersSelector);
  const hasUserApp = useAppSelector(getGithubAppInstallSelector);
  const appWasDeleted = !!users && (!hasUserApp || !users.filter(i => i.githubapp).length || !users.filter(i => i.isConfirmed).length)
  // const appWasDeleted = !!users && (!users[0].isConfirmed || !users[0].githubapp)
  const githubActions = useAppSelector(getGithubActionsSelector);
  const githubActionsTotal = useAppSelector(getGithubActionsTotalSelector);
  const canRestore = useAppSelector(getGithubCanRestore);
  const loading = useAppSelector(isGithubActionsLoadingSelector);
  const trialState = useAppSelector(githubTrialStatus);
  const subInfo = useAppSelector(githubSubscription);
  const isUserSub = useAppSelector(isSubUser);
  const roleApp = useAppSelector(roleByApp);
  const isAdminRole = isUserSub && roleApp?.github === 'admin' && (trialState === 'active' || subInfo?.planName === 'Pro') || !isUserSub

  const [rows, setRows] = useState<Array<IBackupRow> | null>(null);
  const [paymentRequiredState, setPaymentRequiredState] = useState<EPaymentRequiredType>(EPaymentRequiredType.NON);
  const [paymentPoppup, setPaymentPoppup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName,
  });
  const [restartFetch, setRestartFetch] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (githubActionsTotal && githubActionsTotal !== total) {
      setTotal(githubActionsTotal)
    }
  }, [githubActionsTotal])

  useEffect(() => {
    if (!localStorage['githubUser']) {
      navigate('/platform/dashboard/github')
      return
    }
  }, [page, restartFetch])

  useEffect(() => {
    setPaymentRequiredState(
      subInfo?.status === EStripeSubStatus.ACTIVE || trialState === ETrialStatus.STARTED ?
        EPaymentRequiredType.NON :
        !subInfo ? EPaymentRequiredType.TRIAL : EPaymentRequiredType.SUB,
    );
  }, [trialState, subInfo]);

  const restoreItem = (versions: string, name: string, key: string, emails: string[],org:null, place) => {
    if (paymentRequiredState === EPaymentRequiredType.NON) {
      return restore(() => onConfirmRestore(versions, name, key, emails,place), translate, type === 'gist' ? '' : 'github');
    } else {
      if (!isUserSub) {
        setPaymentPoppup((prev) => ({
          ...prev,
          isOpen: true,
        }));
      }
    }
  };

  const closeTrialPoppup = useCallback(() => {
    setPaymentPoppup({ ...paymentPoppup, isOpen: false });
  }, []);

  const onConfirmRestore = async (versionId, name, elementId, emails, place) => {
    if (versionId && elementId) {
      return (
        type === 'gist' ?
          dispatch(restoreGithubGistBackup({
            items: [{
              id: elementId,
              versionid: versionId,
            }],
            emails
          })).unwrap() :
          dispatch(restoreGithubBackup({
            items: [{
              id: elementId,
              versionid: versionId,
              name: name,
              place
            }],
            placeId: Number(localStorage['githubUser']),
            emails
          })).unwrap()
      )
    }
  };

  const downloadZipCallback = useCallback(({ versionid, key }) => {
    dispatch(
      type === 'gist' ?
        downloadGithubGistZip({ id: key, versionId: versionid }) :
        downloadGithubZip({ repoId: key, versionId: versionid })
    )
      .unwrap()
      .then((res) => {
        window.open(`${res.url}`, '_blank');
      })
      .catch((err) => {
        console.log(err);
        const error = err as IError;
        console.log(error);
        if (error.status === 'success') {
          setRestartFetch(prevState => prevState + 1)
          Swal.fire({
            title: translate('notifications.titles.success'),
            text: decoratePopUpMessage(`${error.message as string}. The backup archive preparation can take a few minutes. We will send you an email once it is ready and available to download.`),
            imageUrl: iconNotifySuccess,
          });
        } else {
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.message as string),
            imageUrl: errorBigIcon,
          });
        }
      });
  }, []);

  useEffect(() => {
    !loading && githubActions && setRows(githubActions?.map((props, index) => ({ ...props, id: index })));
  }, [githubActions, loading]);

  useEffect(() => {
    if (!id) {
      navigate('/')
      return;
    }

    dispatch(clearBackupsState());
    updList()
  }, [id]);

  const updList = async (newPage?: number) => {
    try {
      if (!id) {
        return;
      }
      setIsLoading(true)
      await dispatch(getGithubActions({itemId: id, perPage: 10, page: newPage || 0}))
        .unwrap()
        .catch((err) => {
          console.log(err);
          const error = err as IError;
          setIsLoading(false)

          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.error as string),
            imageUrl: errorBigIcon,
          })
            .then(() => {
              navigate('/platform/dashboard/github')
            });
        });
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e);
    }
  }

  return (
    <PlatformActionsPageBlock
      platformName={platformName}
      rows={rows || []}
      downloadZipCallback={downloadZipCallback}
      restoreItemCallback={restoreItem}
      closeTrialPopupCallback={closeTrialPoppup}
      loading={loading}
      total={total}
      page={page}
      setPage={(p)=>{
        setPage(p)
        updList(p)
      }}
      isLoading={isLoading}
      paymentPopup={paymentPoppup}
      paymentRequiredState={paymentRequiredState}
      appWasDeleted={appWasDeleted}
      canRestore={canRestore && !appWasDeleted}
      isAdmin={isAdminRole}
    />
  );
};

export default withRedirectAfterLogout()(GithubActivityPage);
