import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomTooltip} from "./CustomTooltip";
import {Button} from "../../components/StyledComponents";
import {Download} from "react-feather";
import {iframeNewTabMsg, iniFrame} from "../../serviceWorker";


const CustomDownloadButton: React.FunctionComponent<{ disabled: boolean, endDateInMilliseconds: number, downloadUrl?: string, download: () => void }> = ({
                                                                                                                                        endDateInMilliseconds,
                                                                                                                                        downloadUrl,
                                                                                                                                        download, disabled
                                                                                                                                      }) => {
  const {t: translate} = useTranslation();
  const [hoursRemaining, setHoursRemaining] = useState<number | null>(null);
  const [minutesRemaining, setMinutesRemaining] = useState<number | null>(null);

  useEffect(() => {

    function calculateTimeUntilNextDay() {
      if (endDateInMilliseconds > Date.now()) {
        const expiredDate = new Date(endDateInMilliseconds - Date.now());
        const expiredHours = expiredDate.getUTCHours();
        const expiredMinutes = expiredDate.getUTCMinutes();
        setHoursRemaining(expiredHours);
        setMinutesRemaining(expiredMinutes);
      } else {
        setHoursRemaining(null);
        setMinutesRemaining(null);
      }

    }

    if (endDateInMilliseconds > Date.now()) {
      calculateTimeUntilNextDay();
      const currentSeconds = (new Date()).getSeconds();
      const secondsUntilNextMinute = 60 - currentSeconds;
      const millisecondsUntilNextMinute = secondsUntilNextMinute * 1000;
      const intervalId = setInterval(calculateTimeUntilNextDay, millisecondsUntilNextMinute);
      return () => clearInterval(intervalId);
    }

  }, [downloadUrl, endDateInMilliseconds]);

  return (
    <>
      {!iniFrame() && (!!hoursRemaining || !!minutesRemaining) && downloadUrl && !disabled ?
        <a href={downloadUrl}>
          <CustomTooltip
            title={`${translate('views.platform_pages.data_grid.download_button')} ${hoursRemaining && hoursRemaining > 0 ? `${hoursRemaining} ${hoursRemaining === 1 ? 'hour' : 'hours'} ${minutesRemaining} ${minutesRemaining === 1 ? 'minute' : 'minutes'}` : `${minutesRemaining} ${minutesRemaining === 1 ? 'minute' : 'minutes'}`}`}>
            <Button variant="outlined" color="primary">
              <Download className="feather-icon-in-button"/>
              {translate('views.platform_pages.data_grid.save')}
            </Button>
          </CustomTooltip>
        </a> :
        <Button variant="outlined" color="primary" disabled={disabled}
                onClick={() => {
                  if (iniFrame()) {
                    iframeNewTabMsg()
                  } else {
                    download()
                  }
                }}>
          <Download className="feather-icon-in-button"/>
          {translate('views.platform_pages.data_grid.download')}
        </Button>}
    </>
  )
}
export default CustomDownloadButton
