import React, {FunctionComponent, useEffect, useState} from 'react';
import {SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import {
  CheckboxInputChecked,
  CheckboxInputEmpty,
  errorBigIcon,
  toastSuccess
} from '../../../../assets/icons/icons';
import {Button} from '../../../../components/StyledComponents';
import {IError} from '../../../../models/inner-models';
import {IUser} from '../../../../models/models';
import {
  getUserEmailPreferences,
  putUserEmailPreferences,
  unsubscribeMailProfile
} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {getUserSelector} from '../../../../store/selectors';
import {decoratePopUpMessage} from '../../../../utils/popUpTextDecorator';
import './style.scss';
import {Checkbox, CircularProgress} from "@mui/material";
import RadioButton from "../../../../components/Buttons/RadioButton";
import {CustomTooltip} from "../../../../styles/components/CustomTooltip";

interface UserSetProps {
  setLoading: (boolean) => void;
}

interface UserEmailProps {
  is_active: boolean,
  frequency: string,
  report_type?: string,
  report_day?: string,
}

const EmailManage: FunctionComponent<UserSetProps> = ({setLoading}: UserSetProps) => {
  const user: IUser | null = useAppSelector(getUserSelector);
  const dispatch = useAppDispatch();
  const {t: translate} = useTranslation();
  const [isSend, setIsSend] = useState<boolean>(false);
  const [noNeedUpd, setNoNeedUpd] = useState<boolean>(true);
  const [day, setDay] = useState<string>('');
  const [period, setPeriod] = useState<string>('');
  const [reportType, setReportType] = useState<string>('');
  const [emailPref, setEmailPref] = useState<UserEmailProps>();

  const week = {
    sunday: 'SU',
    monday: 'MO',
    tuesday: 'TU',
    wednesday: 'WE',
    thursday: 'TH',
    friday: 'FR',
    saturday: 'SA'
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const data:UserEmailProps = await dispatch(getUserEmailPreferences()).unwrap()
        setPeriod(data?.frequency)
        if (data?.frequency === 'weekly') {
          setDay(data?.report_day || '')
        }
        setReportType(data?.report_type || '')
        setEmailPref(data)
        console.log(data)
      } catch (err) {
        const error = err as IError;
        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      }
    }
    getData()
  }, []);

  const isSameData = () => {
    if (!emailPref) {
      return true
    }
    if (emailPref.frequency !== period || emailPref.report_type !== reportType || (emailPref.report_day && emailPref.report_day !== day)) {
      return false
    }
    return true
  }

  useEffect(() => {
    setNoNeedUpd(isSameData())
  }, [day, reportType, period])

  const submit = async () => {
    try {
      setIsSend(true)
      const dataSend: UserEmailProps = {
        is_active: !!user?.isSubscribedToMail,
        frequency: period,
        report_type: reportType
      }
      if (!!day && period === 'weekly') {
        dataSend.report_day = day
      }
      console.log(dataSend)
      await dispatch(putUserEmailPreferences(dataSend)).unwrap()
        .then((res) => {
          console.log(res)
          Swal.fire({
            title: translate('notifications.titles.success'),
            text: 'Your changes were successfully saved',
            toast: true,
            position: 'top-end',
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true,
            imageUrl: toastSuccess,
            timer: 3000,
          });
          setEmailPref(dataSend)
          setIsSend(false)
          setNoNeedUpd(true)
        })
      setIsSend(false)
    } catch (err) {
      const error = err as IError;
      setIsSend(false)
      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const unsubscribe = async (isSubscribe) => {
    setLoading(true)
    try {
      const unsub = await dispatch(unsubscribeMailProfile(isSubscribe)).unwrap();

      setLoading(false)
      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: translate('forms.sign_up.subscribe.change') || unsub.message,
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError;
      setLoading(false)
      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  return (
    <div className="user-info-block-container email-manage-block">
      <div className="block-label">
        <div className="block-label-title">Email Reporting</div>
        <div className="block-label-desc">Set up you email preferences.</div>
      </div>

      <div className="email-manage-form">
        <div className="label-block">
          {translate('forms.common.subscriptionMail')}
        </div>
        <div className='profile-email-sub'>
          <Checkbox
            className={'checkbox'}
            checked={user?.isSubscribedToMail}
            name="repoName"
            onChange={(e) => {unsubscribe(e.target.checked as boolean)}}
            icon={<CheckboxInputEmpty/>}
            checkedIcon={<CheckboxInputChecked />}
          />
          <span>{translate('subscriptions.emailUnsubscribe')}</span>
        </div>
        <div className='email-freq'>
          <p className="label-block">Email Frequency</p>
          <div className='email-freq-item'>
            <div className='email-freq-item-main'>
              <RadioButton
                disabled={isSend || !user?.isSubscribedToMail}
                checked={period === 'daily'}
                name='daily'
                onClick={() => {
                  setPeriod('daily')
                  if (!reportType) {
                    setReportType('all')
                  }
                }}
              />
              <span>
                Daily
              </span>
            </div>
          </div>
          <div className='email-freq-item'>
            <div className='email-freq-item-main'>
              <RadioButton
                checked={period === 'weekly'}
                disabled={isSend || !user?.isSubscribedToMail}
                name='weekly'
                onClick={() => {
                  setPeriod('weekly')
                  if (!day) {
                    setDay('monday')
                  }
                }}
              />
              <span>
                Weekly
              </span>
            </div>
            <div className='email-freq-item-sub-week'>
              {Object.keys(week).map(item => {
                return (
                  <div
                    key={item}
                    className={`email-freq-day-week ${day === item ? 'day-week--active' : ''} ${isSend || period !== 'weekly' || !user?.isSubscribedToMail ? 'day-week--disabled' : ''}`}
                    onClick={() => {setDay(item)}}
                  >
                    <span>
                      {week[item]}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='email-freq'>
          <p className="label-block">Notification Type</p>
          <div className='email-freq-item'>
            <div className='email-freq-item-sub'>
              <CustomTooltip title='Send Successful emails (Successful backups & Successful Restores)' placement="left-start">
                <div className='email-freq-item-sub-item'>
                  <Checkbox
                    className={'checkbox'}
                    checked={reportType === 'succeeded' || reportType === 'all'}
                    name="repoName"
                    onChange={() => {
                      setReportType(prevState => {
                        if (prevState === 'all') {
                          return 'failed'
                        } else if (prevState === '' || prevState === 'succeeded') {
                          return 'succeeded'
                        } else {
                          return 'all'
                        }
                      })
                    }}
                    icon={<CheckboxInputEmpty/>}
                    checkedIcon={<CheckboxInputChecked />}
                    disabled={isSend || !user?.isSubscribedToMail}
                  />
                  <span>Successful Backups</span>
                </div>
              </CustomTooltip>
              <CustomTooltip title='Send Failed emails (Failed backups & Failed Restores)' placement="left-start">
                <div className='email-freq-item-sub-item'>
                  <Checkbox
                    className={'checkbox'}
                    checked={reportType === 'failed' || reportType === 'all'}
                    name="repoName"
                    onChange={() => {
                      setReportType(prevState => {
                        if (prevState === 'all') {
                          return 'succeeded'
                        } else if (prevState === '' || prevState === 'failed') {
                          return 'failed'
                        } else {
                          return 'all'
                        }
                      })
                    }}
                    icon={<CheckboxInputEmpty/>}
                    checkedIcon={<CheckboxInputChecked />}
                    disabled={isSend || !user?.isSubscribedToMail}
                  />
                  <span>Failed Backups</span>
                </div>
              </CustomTooltip>
            </div>
          </div>
        </div>

        <div className="action-row">
          <Button
            onClick={submit}
            variant="contained"
            color="primary"
            disabled={isSend || !user?.isSubscribedToMail || noNeedUpd}
          >
            {isSend &&
              <div className="small-spinner-wrapper">
                <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
              </div>
            }
            {translate('notifications.choices.save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailManage;
