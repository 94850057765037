import axios from 'axios';
import Swal from 'sweetalert2';
import { errorBigIcon } from '../../assets/icons/icons';
import { checkTokenExpiration } from '../../cognito/cognitoTest';
import { ELocalStoreKeys } from '../../models/consts';

const getToken = () => {
  return localStorage.getItem(ELocalStoreKeys.TOKEN);
};

const defaultHeaders = { 'Content-Type': 'appliction/json' };
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: defaultHeaders,
});

instance.interceptors.request.use(async (config) => {
  const hasToken = !!getToken();
  if (hasToken) {
    try {
      const token = await checkTokenExpiration();
      config.headers['Authorization'] = `${token}`;
    } catch (err) {
      console.log(err);
      config.headers['Authorization'] = `jkhkjhjhkjhkjhkjhkjhljljljoi78y87987h8h8hn`;
      localStorage.clear();
      throw {response: err}
    }
  } else if ( window.location.pathname !== '/verify-email' && window.location.hash !== '#resend_verification') {
    throw {
      status: 423,
      message: 'Authorization Required',
      error: '423 Locked',
    };
  }
  return config;
});

instance.interceptors.response.use(function (response) {
  //here if response OK
  if (response.status >= 500 && response.status < 600) {
    Swal.fire({
      title: 'Internal server error',
      text: 'Server response with error, please try again later',
      imageUrl: errorBigIcon,
    });
  }
  if(response.status === 401) {
    // localStorage.clear();
  }
  return response;
}, function (error) {
  if (error.code === 'ERR_CANCELED'&& error.name ==='CanceledError') {
    return;
  }
  console.log(error)
  console.log(error.response)
  if(error.response?.status === 401 || error.status === 423) { // || !error.response
    localStorage.clear();
    if (window.location.pathname !== '/auth/login') {
      window.location.pathname = '/auth/login'
    }
    if (error.response?.status === 401) {
      Swal.fire({
        title: 'Unauthorized Access',
        text: 'Authorization Required',
        imageUrl: errorBigIcon,
      });
    }
    return {data:{status: 'success'}} //todo fix 401 error
  } //else
  if(error.response?.status === 403 && window.location.pathname !== '/wait') {
    // window.location.pathname = '/wait'
    if (error.config?.url === 'user/get-user-start-info') {
      return Promise.reject(error);
    }
  } else if (error.code === "ERR_NETWORK") {
    Swal.fire({
      title: 'Internal server error',
      text: 'Server response with error, please try again later',
      imageUrl: errorBigIcon,
    });
    console.log(error)
    return Promise.reject({...error, status: 'failed', code: 500, message: 'Server response with error, please try again later'});
  } else {
    return Promise.reject(error);
  }
});

export default instance;
