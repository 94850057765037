import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Checkbox,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
  getGithubUsersSelector,
  getGitlabUsersSelector,
  getJiraUsersSelector,
  getTrelloUsersSelector,
  loadingUser
} from '../../../store/selectors';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import './style.scss';
import styled from 'styled-components';
import {AlertTriangle, ArrowLeft, Check, ExternalLink, Info, Plus, RefreshCcw, X} from "react-feather";
import Platform from "../../../models/platforms-data";
import {Button, TextField} from "../../../components/StyledComponents";
import {useNavigate} from "react-router-dom";
import AddRestoreItem from "./AddRestoreItem/AddRestoreItem";
import {
  fetchGithubUsers,
  fetchGitlabUsers,
  fetchJiraUser,
  fetchTrelloUser,
  fetchItemsForJiraRestore,
  getBackupByItemsId,
  getJiraProjectColumns,
  getUserProfileName,
  restoreGithubBackup,
  restoreGithubGistBackup,
  restoreGitlabBackup,
  restoreGitlabSnippetsBackup,
  restoreJiraBackup,
  restoreTrelloBackup
} from "../../../store/actions";
import {IError} from "../../../models/inner-models";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import {CheckboxInputChecked, CheckboxInputEmpty, errorBigIcon, toastSuccess} from "../../../assets/icons/icons";
import {IRestoreData, IRestorePayload} from "../../../models/models";
import DatabaseIcoInvert from "../../../assets/icons/CustomSvg/DatabaseIcoInvert";
import JiraColumnMatch from "../../PlatformPages/JiraPages/JiraActivityView/JiraColumnMatch/JiraColumnMatch";
import Modal from "../../../components/Dialogs/Modal/Modal";
import Autocomplete from '@mui/material/Autocomplete';
import {CustomTooltip} from "../../../styles/components/CustomTooltip";
import {BadgesIFrameMode, BadgesJiraError} from "../../../components/Badges/Badges";
import ValidationErrorBlock from "../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock";
import {JiraProjectsTypeKey, validateEmails} from "../../../models/consts";
import {iframeNewTabMsg, iniFrame} from "../../../serviceWorker";

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 24px;
    box-sizing: border-box;
  }
`;

interface IMatchData {
  restoredProjectColumn: {
    name: string;
    statusesIds?: string[]
  };
  backedUpProjectColumn: {
    transitionId: string;
    name: string;
  }
}

type StepType = {
  step: number;
  stepCur?: number;
  title: string;
  desc: string;
}

type TableType = {
  id: string;
  isGist: boolean;
  idName: string;
  ver: string;
  verDate: number;
  name: string;
  type?: string;
  projectTypeKey?:string,
  matchedColumns?: IMatchData[]
}
const Steps = ({step, stepCur, title, desc}: StepType) => {
  return (
    <div className={'restore-step ' + (step === stepCur ? ' active-step' : '') + (stepCur && step < stepCur ? ' done-step' : '')}>
      <div className={'restore-step-icon' + (stepCur && step < stepCur ? ' done-step' : '')}>
        {stepCur && step >= stepCur ?
          '0' + step :
          <Check/>
        }
      </div>
      <div className='restore-step-text'>
        <div className='restore-step-title'>
          {title}
        </div>
        <div className='restore-step-desc'>
          {desc}
        </div>
      </div>
    </div>
  )
}

const BulkRestore: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userLoading = useAppSelector(loadingUser);
  const users = useAppSelector(getGithubUsersSelector);
  const jiraUser = useAppSelector(getJiraUsersSelector);
  const trelloUser = useAppSelector(getTrelloUsersSelector);
  const gitlabUser = useAppSelector(getGitlabUsersSelector);
  const [stepCur, setStepCur] = useState<number>(1);
  const [confirmCount, setConfirmCount] = useState<number>(0);
  const [platformName, setPlatformName] = useState<string>('');
  const [emailsInput, setEmailsInput] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<{ id: string; name: string; }[]>([]);
  const [jiraRestoreTo, setRestoreTo] = useState<{ id: string; name: string; projectTypeKey:string }[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isRestore, setIsRestore] = useState<boolean>(false);
  const NEWNAME_REGEX = /^[a-zA-Z0-9](?!.*[ .+_-]{2,})(?!.*\s{2,})[a-zA-Z0-9_ .+-]*[a-zA-Z0-9]+$/
  // /^[a-zA-Z0-9_](?!.*[.+_-]{2,})(?!.*\s{2,})(?!(.* [.+_-]{1}){2,})(?!(.*[.+_-]{1} [.+_-]{1}){1})[a-zA-Z0-9_ .+-]+$/;
  const [restoreTable, setRestoreTable] = useState<TableType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [backupsVers, setBackupsVers] = useState<Record<string, any>>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [modalData, setModalData] = useState<Record<string, any>>([])
  const [matchData, setMatchData] = useState<Array<IMatchData>>([])
  const [curMatch, setCurMatch] = useState<string>('')
  const [curMatchNew, setCurMatchNew] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profiles, setProfiles] = useState<Record<string, any>>({});
  const [isEmailsValid, setIsEmailsValid] = useState<boolean>(true);
  const isSubPlatform = platformName === 'gitlab' ? localStorage['gitlabType'] === 'snippet' : platformName === 'github' ? Number(localStorage['githubUser']) === 0 : false

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resProf = await dispatch(getUserProfileName()).unwrap()
        if (resProf) {
          setProfiles(resProf)
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [])

  const getVers = async (ids: string[], platform: string) => dispatch(getBackupByItemsId({ids, platform})).unwrap()
  const getJiraList = async () => dispatch(fetchItemsForJiraRestore({})).unwrap()

  const fillMatchData = (obj: IMatchData) => {
    setMatchData(prevState => {
      const indexToUpd = prevState.findIndex(item => item.backedUpProjectColumn.name === obj.backedUpProjectColumn.name);
      if (indexToUpd !== -1) {
        prevState[indexToUpd].restoredProjectColumn.name = obj.restoredProjectColumn.name
      } else {
        prevState.push(obj)
      }
      return [...prevState]
    })
  }

  const applyMatchData = () => {
    setRestoreTable(prevState => {
      const indexToUpd = prevState.findIndex(item => item.idName === curMatch);
      prevState[indexToUpd].matchedColumns = matchData
      return [...prevState]
    })
  }

  const openMatchModal = (row:TableType) => {
    setShowModal(true)
    const newProj = jiraRestoreTo?.find(i=>i.id === row.name)?.name || ''
    if (!curMatch || curMatch !== row.idName || !curMatchNew || curMatchNew !== newProj) {
      setModalData({})
      setCurMatch(row.idName)
      setCurMatchNew(newProj)
      if (row.matchedColumns) {
        setMatchData(row.matchedColumns)
      } else {
        setMatchData([])
      }
      dispatch(getJiraProjectColumns({backedUpProjectId: row.id, restoredProjectId: row.name, versions: row.ver}))
        .unwrap()
        .then(res => {
          setModalData(res)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const updTableRows = (list) => {
    const idList = list.map(item => item.id)
    const platform = !!platformName.length ? platformName : localStorage['bulkPlatform'];

    getVers(idList, platform)
      .then(res => {
        setBackupsVers(res.result)
        if (res.result && !Object.keys(res.result).length) {
          setSelectedItem([])
        } else {
          const tempArr: TableType[] = [];

          list.forEach(item => {
            const findItem = restoreTable.find(({id}) => id === item.id)

            if (!findItem) {
              let jiraNewId = item.id
              let i = 0;

              if (platformName === 'jira' && !!jiraRestoreTo.length && restoreTable.findIndex(({name}) => name === item.id) !== -1) {
                jiraNewId = ''
                while (jiraNewId === '') {
                  if (restoreTable.findIndex(({name}) => name === jiraRestoreTo[i].id) === -1) {
                    jiraNewId = jiraRestoreTo[i].id
                  }
                  i++
                }
              }
              const isGist = isSubPlatform && platformName === 'github';

              const temp = {
                id: item.id,
                isGist,
                idName: item.name?.split("::")?.[0],
                ver: res.result?.[item.id]?.[0]?.version || '',
                verDate: res.result?.[item.id]?.[0]?.date || 0,
                projectTypeKey:item.projectTypeKey,
                name: platformName === 'jira' ?
                  '' :
                  isGist ?
                    item.name?.split("::")?.[0] :
                    item.name?.split("::")?.[0] + '_restored'
              }
              if (res.result?.[item.id]) {
                tempArr.push(temp)
              }
            }
          })
          setRestoreTable([...restoreTable, ...tempArr])
        }
      })
  }

  const onDeleteItem = (id: string) => {
    setSelectedItem(prevState => prevState.filter(item => item.id !== id))
    setRestoreTable(prevState => prevState.filter(item => item.id !== id))
  }

  useEffect(() => {
    if (!selectedItem.length && !platformName.length) {
      const curPlatform = localStorage['bulkPlatform']
      const preSelectedItem = localStorage['selectedItem']
      if (curPlatform && preSelectedItem && JSON.parse(preSelectedItem)) {
        const itemLocal = JSON.parse(preSelectedItem)
        setPlatformName(curPlatform)
        setSelectedItem(itemLocal)
        // updTableRows(itemLocal)
        if ((curPlatform === 'github' && !localStorage['githubUser']) || (curPlatform === 'gitlab' && !localStorage['gitlabType'])) {
          navigate('/')
        }
      } else {
        navigate('/')
      }

      if (!jiraRestoreTo.length) {
        getJiraList()
          .then(res => {
            setRestoreTo(res.available_projects)
          })
      }
    }
  }, [selectedItem, platformName])

  useEffect(() => {
    if (!!selectedItem.length && selectedItem.length > restoreTable.length) {
      updTableRows(selectedItem)
    }
    if (!selectedItem.length) {
      setBackupsVers({})
    }
  }, [selectedItem])

  useEffect(() => {
    switch (platformName) {
      case 'github':
        dispatch(fetchGithubUsers)
        // isSubPlatform ? dispatch(fetchGistForGithubUser({page: 0, perPage: 20})).unwrap() : dispatch(fetchItemsForGithubUser({page: 0, perPage: 20, placeId: localStorage['githubUser'] as number})).unwrap()
        break;
      case 'gitlab':
        dispatch(fetchGitlabUsers)
        // dispatch(isSubPlatform ? fetchSnippetsForGitlabUser({page: 0, perPage: 20}) : fetchItemsForGitlabUser({page: 0, perPage: 20})).unwrap()
        break;
      case 'trello':
        dispatch(fetchTrelloUser)
        // dispatch(fetchItemsForTrelloUser({page: 0, perPage: 20})).unwrap()
        break;
      case 'jira':
        dispatch(fetchJiraUser)
        // dispatch(fetchItemsForJiraUser({page: 0, perPage: 20})).unwrap()
        break;
    }

  }, [platformName])

  const stepsList: StepType[] = [
    {
      step: 1,
      title: 'Select backups',
      desc: `To which backup points should we restore your ${isSubPlatform ? (platformName === 'github' ? 'gists' : 'snippets') : translate(`views.bulk_restore.itemName.${platformName}.many`)}?`
    },
    {
      step: 2,
      title: 'Confirm backup plan',
      desc: 'Let’s make sure we’ve got all the right in place.'
    }
  ];

  const onBack = () => {
    if (stepCur === 1) {
      window.history.back()
    } else {
      setConfirmCount(0)
      setStepCur(1)
    }
  }

  const onRestore = async () => {
   try {
     setIsRestore(true)
     const payload:IRestorePayload = {items: []}
     const isGist = isSubPlatform
     if (!!emailsInput?.replaceAll(' ', '').length) {
       payload.emails = emailsInput.replaceAll(' ', '').split(',');
     }
     switch (platformName) {
       case 'github':
         if (isGist) {
           restoreTable.forEach(item => {
             payload.items.push({id: item.id, versionid: item.ver})
           })
           await dispatch(restoreGithubGistBackup(payload)).unwrap();
         } else {
           restoreTable.forEach(item => {
             payload.items.push({id: item.id, versionid: item.ver, name: item.name})
           })
           payload.placeId = Number(localStorage['githubUser'])
           await dispatch(restoreGithubBackup(payload)).unwrap();
         }
         break;
        case 'gitlab':
         restoreTable.forEach(item => {
           payload.items.push({id: item.id, versionId: item.ver, name: item.name})
         })
         await dispatch(isSubPlatform ? restoreGitlabSnippetsBackup(payload) : restoreGitlabBackup(payload)).unwrap();
         break;
       case 'trello':
         restoreTable.forEach(item => {
           payload.items.push({id: item.id, versions: item.ver, name: item.name})
         })
         await dispatch(restoreTrelloBackup(payload)).unwrap();
         break;
       case 'jira':
         restoreTable.forEach(item => {
           const newItem: IRestoreData  = {id: item.id, versions: item.ver, restoredId: item.name}
           if (item.matchedColumns) {
             newItem.matchedColumns = item.matchedColumns
           }
           payload.items.push(newItem)
         })
         await dispatch(restoreJiraBackup(payload)).unwrap();
         break;
       default:
         navigate('/');
         break;
     }

     await Swal.fire({
       title: translate('notifications.titles.success'),
       text: 'Your restores are now in process',
       toast: true,
       position: 'top-end',
       timerProgressBar: true,
       showConfirmButton:false,
       showCloseButton: true,
       imageUrl: toastSuccess,
       timer: 3000,
     });

     localStorage.removeItem('bulkPlatform')
     localStorage.removeItem('selectedItem')

     if (window.location.pathname === '/bulk-restore') {
       navigate('/')
     }

   } catch (err) {
     const error = err as IError;
     console.log(err);
     setIsRestore(false)
     Swal.fire({
       title: translate('notifications.titles.error'),
       text: decoratePopUpMessage(error.error as string),
       imageUrl: errorBigIcon,
     });
   }
  }

  const curUser = () =>{
    switch (platformName) {
      case 'github':
        return users ?
          !isSubPlatform ?
            users.filter(i=>i.id === Number(localStorage['githubUser']))[0] :
            users.filter(i=>i.userType === 'User')[0]
          : {}
      case 'trello':
        return trelloUser ? trelloUser[0] : {}
      case 'jira':
        return jiraUser ? jiraUser[0] : {}
      case 'gitlab':
        return gitlabUser ? gitlabUser[0] : {}
      default:
        return {}
    }
  }

  const checkEmail = ()=>{
    const isEmailsValid = typeof validateEmails(emailsInput) === "boolean"
    setIsEmailsValid(isEmailsValid)
  }

  return (
    <div id="restore-page">
      {userLoading || !platformName.length || (!Object.keys(backupsVers).length && !!selectedItem.length) ||
      (platformName === 'jira' && !jiraRestoreTo.length && !!selectedItem.length) ?
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div> :
        <div className="restore-block">
          <div className="header-block actions-header">
            <div className='actions-header-left' onClick={() => navigate(`/platform/dashboard/${platformName}`)}>
              <ArrowLeft className="arrow-icon"/>
              {isSubPlatform ? (platformName === 'github' ? 'Back to All Gists' : 'Back to All Snippets') : translate(`views.platform_pages.actions_list.backto_${platformName}`)}
            </div>
            <div className='actions-header-right'>
              <Button variant="outlined" color="primary"
                      onClick={() => {
                        if (iniFrame()) {
                          iframeNewTabMsg()
                        } else if (!iniFrame() && profiles[platformName]) {
                          switch (platformName) {
                            case 'github':
                              window.open(`https://github.com/${profiles.github[Object.keys(profiles.github)[0]]}`, '_blank')
                              break;
                            case 'gitlab':
                              window.open(`https://gitlab.com/dashboard/projects`, '_blank')
                              break;
                            case 'trello':
                              window.open(`https://trello.com/u/${profiles.trello}/boards`, '_blank')
                              break;
                            case 'jira':
                              window.open(`https://${profiles.jira}.atlassian.net/jira/your-work`, '_blank')
                              break;
                            case 'notion':
                            default:
                              window.open(Platform[platformName].url, '_blank')
                              break
                          }
                        } else {
                          window.open(Platform[platformName].url, '_blank')
                        }
                      }}
              >
                {translate('common.buttons.visit')} {Platform[platformName].title}
                <ExternalLink className="feather-icon-in-button icon-ml"/>
              </Button>
            </div>
          </div>
          <Typography variant="h3" display="block" mb='8px'>
            {translate('views.bulk_restore.title')}
          </Typography>
          {iniFrame() &&
            <BadgesIFrameMode/>
          }
          <div className='header-block action-page-platform-logo'>
            <img src={Platform[platformName]?.smallLogo} className="platform-logo" loading="lazy"/>
            {Platform[platformName]?.title}
          </div>
          <div className='restore-actions'>
            {stepsList.map(item => {
              return (
                <Steps key={item.step} step={item.step} stepCur={stepCur} title={item.title} desc={item.desc}/>
              )
            })}
          </div>

          {platformName === 'jira' && !!restoreTable.filter(i => i.id === i.name)?.length && (
            <BadgesJiraError platformName={platformName}/>
          )}

          <Typography variant="h4" display="block" mb='16px'>
            {stepsList[stepCur - 1].title}
          </Typography>

          <Card>
            <CardContent>
              <div className='restore-content-sub-title'>
                {stepCur === 1 ? translate('views.bulk_restore.subTitle') : translate('views.bulk_restore.subTitleTwo')}
              </div>
              <Table className={stepCur === 1 ? 'restore-table-1' : 'restore-table-2'}>
                <TableHead>
                  <TableRow>
                    <TableCell>{isSubPlatform ? (platformName === 'github' ? 'gist' : 'snippet'): translate(`views.bulk_restore.itemName.${platformName}.one`)}</TableCell>
                    {stepCur === 1 ?
                      <>
                        <TableCell>Select a backup point</TableCell>
                        {platformName === 'jira' ?
                          <TableCell>Select a project to restore to</TableCell> :
                          isSubPlatform ?
                            <TableCell>{`New ${platformName === 'github' ? 'gist' : 'snippet'} name `} {platformName === 'gitlab' && <span>(Optional)</span>}</TableCell> :
                            <TableCell>New {translate(`views.bulk_restore.itemName.${platformName}.one`)} name <span >(Optional)</span></TableCell>
                        }
                      </> :
                      <>
                        <TableCell>Action</TableCell>
                        {platformName === 'jira' &&
                          <TableCell>Match columns</TableCell>
                        }
                        <TableCell><b>I confirm*</b></TableCell>
                      </>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!restoreTable.length && (
                    <div className='restore-content-empty'>
                      Add your items to restore
                    </div>
                  )}
                  {restoreTable.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <DatabaseIcoInvert className="feather-icon-in-button"/>
                        {row.idName}
                      </TableCell>
                      {stepCur === 1 ?
                        <>
                          <TableCell>
                            <Select
                              defaultValue={`${row.ver}@${row.verDate}`}
                              onChange={(e) => {
                                setRestoreTable(prevState => {
                                  const val = e.target.value.split('@')
                                  prevState[index].ver = val[0]
                                  prevState[index].verDate = +val[1]
                                  return [...prevState]
                                })
                              }}
                              MenuProps={{id: 'restore-select'}}
                              className='restore-item-select-ver'
                            >
                              {backupsVers?.[row.id]?.map((itemVer, inNum) => {
                                if (inNum < 150) {
                                  const text = new Date(itemVer.date).toLocaleString()
                                  return (
                                    <MenuItem value={`${itemVer.version}@${itemVer.date}`} key={itemVer.version}>
                                      {text}
                                    </MenuItem>
                                  )
                                }
                              })}
                            </Select>
                          </TableCell>
                          <TableCell className={platformName === 'gitlab' ? 'new-name-input' : ''}>
                            {platformName !== 'jira' ?
                              <OutlinedInput
                                className={!!row.name.length ? 'restore-new-name' : 'restore-new-name error-new-name'}
                                name={row.id}
                                onChange={(e) => {
                                  setRestoreTable(prevState => {
                                    prevState[index].name = e.target.value
                                    return [...prevState]
                                  })
                                }}
                                placeholder={`New item name`}
                                defaultValue={row.isGist ? 'N/A' : row.name}
                                required
                                disabled={row.isGist}
                              /> :
                              <Autocomplete
                                className='restore-item-select-ver jira-select'
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                ListboxProps={{id: 'restore-select'}}
                                id={'auto-'+row.id}
                                getOptionLabel={option => {
                                  return option?.label || ""
                                }}
                                value={row.name ? {id: row.name, label: jiraRestoreTo?.find(i=>i.id === row.name)?.name || ''} : undefined}
                                onChange={(_, n)=>{
                                  setRestoreTable(prevState => {
                                    delete prevState[index].matchedColumns
                                    prevState[index].name = n?.id || ''
                                    return [...prevState]
                                  })
                                }}
                                options={
                                  [...jiraRestoreTo?.map((item) => {
                                    if (restoreTable.findIndex(res => res.name === item.id && row.name !== res.name) === -1 && item.projectTypeKey === row.projectTypeKey) {
                                      return (
                                        { id: item.id, label: row.idName === item.name ? (item.name + ' ⚠') : item.name }
                                      )
                                    }
                                  })].filter(Boolean)
                                }
                                renderInput={(params) => <TextField {...params} placeholder='Search project'/>}
                              />
                            }
                            {platformName === 'jira' && row.id === row.name && (
                              <CustomTooltip title='This is the same project as your backup'>
                                <AlertTriangle className='jira-confirm-alert'/>
                              </CustomTooltip>
                            )}
                            <X className={`feather-icon-in-button icon-ml ${platformName === 'jira' ? 'icon-x' : ''}`} onClick={()=>onDeleteItem(row.id)}/>
                            {platformName === 'gitlab' && !NEWNAME_REGEX.test(row.name) &&
                              <ValidationErrorBlock errorMessage={'Name must start with a letter or number. Names cannot contain spaces, dots, dashes, or special characters.'}/>
                            }
                          </TableCell>
                        </> :
                        <>
                          {platformName === 'jira' ?
                            <TableCell>
                              {translate(`views.bulk_restore.actionTextJira`,
                                {
                                  type: translate(`views.bulk_restore.itemName.${platformName}.one`),
                                  date: new Date(row.verDate).toLocaleString(),
                                  name: jiraRestoreTo?.find(el=>el.id === row.name)?.name
                                }).replaceAll('&#x2F;', '/')
                              }
                            </TableCell> :
                            <TableCell>
                              {translate(`views.bulk_restore.actionText`,
                                {
                                  type: isSubPlatform ? (platformName === 'github' ? 'gist' : 'snippets') : translate(`views.bulk_restore.itemName.${platformName}.one`),
                                  date: new Date(row.verDate).toLocaleString(),
                                  name: row.name
                                }).replaceAll('&#x2F;', '/')
                              }
                            </TableCell>
                          }
                          {platformName === 'jira' && (
                            <TableCell>
                              <Button variant='outlined' color='primary' disabled={row.projectTypeKey !== JiraProjectsTypeKey.software} onClick={()=>openMatchModal(row)}>
                                Assign issues
                              </Button>
                              { row.projectTypeKey !== JiraProjectsTypeKey.software && <CustomTooltip title='Matching is not applicable as Jira Service Management doesn’t have columns. '>
                                  <Info style={{ marginLeft: '5px', display: 'inline-block', verticalAlign: 'middle' }} className="sliders-icon"/></CustomTooltip>}
                            </TableCell>
                          )}
                          <TableCell >
                            <Checkbox
                              color="primary"
                              onChange={(event) => {
                                setConfirmCount(prevState => (
                                  event.target.checked ?
                                    prevState + 1 :
                                    prevState - 1
                                ))
                              }}
                              disabled={platformName === 'jira' && !row.matchedColumns && row.projectTypeKey === JiraProjectsTypeKey.software}
                              icon={<CheckboxInputEmpty/>}
                              checkedIcon={<CheckboxInputChecked />}
                            />
                            {platformName === 'jira' && !row.matchedColumns && row.projectTypeKey === JiraProjectsTypeKey.software && (
                              <CustomTooltip title='Please match the columns'>
                                <AlertTriangle className='jira-confirm-alert'/>
                              </CustomTooltip>
                            )}
                          </TableCell>
                        </>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {stepCur === 1 ?
                <div className='restore-add-btn'>
                  <CustomTooltip title={selectedItem.length === 50 ? 'You can restore up to 50 items at a time' : ''}>
                    <Button
                      variant="contained" color="secondary"
                      className='restore-add'
                      disabled={selectedItem.length === 50 || selectedItem.length > restoreTable.length}
                      onClick={() => setIsOpen(true)}
                    >
                      {(selectedItem.length > restoreTable.length) ?
                        <div className="small-spinner-wrapper">
                          <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                        </div> :
                        <Plus className="feather-icon-in-button"/>
                      }
                      Add {isSubPlatform ? (platformName === 'github' ? 'gists' : 'snippets') : translate(`views.bulk_restore.itemName.${platformName}.many`)}
                    </Button>
                  </CustomTooltip>
                </div> :
                <div className='restore-bottom-emails'>
                  <div className="label-block">{translate('forms.restoreModal.sendEmail')}</div>
                  <OutlinedInput
                    className='restore-emails'
                    name='emails'
                    placeholder='your@email.com'
                    error={!isEmailsValid}
                    onBlur={()=>checkEmail()}
                    onChange={(e) => {
                      setEmailsInput(e.target.value)
                      setIsEmailsValid(true)
                    }}
                  />
                  {!isEmailsValid?
                    <small className='error-message'>
                      {validateEmails(emailsInput)}
                    </small> :
                  <small>
                    {translate('forms.restoreModal.sendSub')}
                    <br/><br/>
                    * This restore process will create a new version and not overwrite.
                  </small>}
                </div>
              }

              <Button
                variant="outlined" color="primary"
                className='restore-back'
                onClick={onBack}
              >
                Back
              </Button>
              {stepCur === 1 ?
                <Button
                  variant="contained" color="primary"
                  className='restore-next'
                  disabled={!restoreTable?.length || !!restoreTable.filter(item => !item.name.length || (platformName === 'gitlab' && !NEWNAME_REGEX.test(item.name))).length}
                  onClick={() => setStepCur(prevState => prevState + 1)}
                >
                  Continue
                </Button> :
                <Button
                  variant="contained" color="primary"
                  className='restore-next'
                  onClick={onRestore}
                  disabled={confirmCount !== restoreTable.length || isRestore || !isEmailsValid}
                >
                  <RefreshCcw className="feather-icon-in-button"/>
                  Restore {restoreTable.length} {isSubPlatform ? (platformName === 'github' ? 'gist' : 'snippet') : translate(`views.bulk_restore.itemName.${platformName}.many`)}
                </Button>
              }
            </CardContent>
          </Card>
          <AddRestoreItem
            isOpen={isOpen}
            user={curUser()}
            closeNotification={() => setIsOpen(false)}
            platformName={platformName}
            addItems={(newItems)=>setSelectedItem(prevState => ([...prevState, ...newItems]))}
            selectedItem={selectedItem}
          />
        </div>
      }
      <Modal
        isOpen={showModal}
        closeNotification={() => {
          setShowModal(false)
        }}
        title={`Match fields for ${curMatch} project`}
        child={
          <JiraColumnMatch
            close={() => {
              setShowModal(false)
            }}
            fillData={fillMatchData}
            platformName={platformName}
            date={modalData}
            projName={{old: curMatch, new: curMatchNew}}
            restore={() => {
              applyMatchData()
              setShowModal(false)
            }}
            matchData={matchData}
            isBulk={true}
          />
        }
      />
    </div>
  );
};

export default withRedirectAfterLogout()(BulkRestore);
