import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { IError } from '../../../models/inner-models';
import {
  confirmGithubUser as confirmGithubUserAction, fetchGithubUsers, getAllSubscriptions, getUser,
  sendConfirmGithubUser as sendConfirmGithubUserAction,
} from '../../../store/actions';
import { useAppDispatch } from '../../../store/hooks';
import { decoratePopUpMessage } from '../../../utils/popUpTextDecorator';
import { Button } from '../../StyledComponents';
import './style.scss';
import {errorBigIcon, iconNotifySuccess, iconNotifyWarningInfo} from "../../../assets/icons/icons";
import {IPlatformUser} from "../../../models/models";
import {iniFrame} from "../../../serviceWorker";

interface IGithubUserConfirmProps {
  noApp?: boolean
  updUserList?: (userList: IPlatformUser[] | null)=>void
  isOwner: boolean
}

const UserNotConfirmedBlock: FunctionComponent<IGithubUserConfirmProps> = ({noApp, updUserList, isOwner}:IGithubUserConfirmProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const [codeSent, setCodeSent] = useState<boolean>(false);

  const sendConfirmationCode = useCallback(async () => {
    try {
      const message = await dispatch(sendConfirmGithubUserAction()).unwrap();
      setCodeSent(true);

      await Swal.fire({
        imageUrl: iconNotifySuccess,
        title: translate('notifications.titles.success'),
        text: decoratePopUpMessage(message),
        cancelButtonText: translate('notifications.choices.close'),
        showCancelButton: true,
        confirmButtonText: translate('notifications.choices.enter_code'),
        preConfirm: () => {
          return confirmUser();
        },
      });
    } catch (e) {
      const error = e as IError;
      setCodeSent(false);

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        showConfirmButton: true,
        confirmButtonText: error.code === "err.app.missing" ?
          translate('forms.github_add_edit_user.github_app') :
          error.code === "err.app.delay" ?
            translate('notifications.choices.enter_code') :
            translate('common.default_pages.contact_us'),
        showCancelButton: true,
        cancelButtonText: translate('notifications.choices.close'),
        imageUrl: errorBigIcon,
      })
        .then(res => {
          if (res.value) {
            switch (error?.code) {
              case "err.app.missing":
                window.open(process.env.REACT_APP_GITHUB_APP_INSTALL_URL, '_blank')
                break;
              case "err.app.delay":
                return confirmUser();
              default:
                window.location.pathname = '/contact'
                break
            }
          }
        });
    }
  }, []);

  const onConfirmUser = async (code: string) => {
    try {
      const message = await dispatch(confirmGithubUserAction(code)).unwrap();
      let usersList: { result: IPlatformUser[] | null } = {result: null}
        usersList = await dispatch(fetchGithubUsers()).unwrap().catch(err => console.log(err));
      updUserList && updUserList(usersList?.result)
      dispatch(getUser()).unwrap();
      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: decoratePopUpMessage(message as string),
        confirmButtonText: translate('notifications.choices.close'),
        imageUrl: iconNotifySuccess,
      });
      // window.location.reload()
    } catch (e) {
      const error = e as IError;

      await Swal.fire({
        imageUrl: errorBigIcon,
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const sendCodeDialog = useCallback(async () => {
    if (codeSent) {
      return confirmUser();
    }

    return Swal.fire({
      title: translate('notifications.github_user.confirm_ownership'),
      text: translate('notifications.github_user.confirm_user'),
      showCancelButton: true,
      cancelButtonText: translate('notifications.choices.cancel'),
      denyButtonText: translate('notifications.choices.enter_code'),
      confirmButtonText: translate('notifications.choices.send'),
      imageUrl: iconNotifyWarningInfo,
      showDenyButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return sendConfirmationCode();
      },
      preDeny: () => {
        setCodeSent(true);
        confirmUser()
      },
    });
  }, [codeSent]);

  const confirmUser = async () => {
    await Swal.fire({
      text: 'Enter confirmation code',
      input: 'text',
      imageUrl: iconNotifyWarningInfo,
      inputAttributes: {
        autocapitalize: 'off',
      },
      confirmButtonText: translate('notifications.choices.confirm'),
      cancelButtonText: translate('notifications.choices.close'),
      denyButtonText: translate('notifications.choices.resend'),
      showCancelButton: true,
      showDenyButton: true,
      showLoaderOnConfirm: true,
      showLoaderOnDeny: true,
      customClass: {
        container: 'confirm-input-modal'
      },
      preConfirm: (code) => {
        return onConfirmUser(code);
      },
      preDeny: () => {
        return sendConfirmationCode();
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  useEffect(() => {
    if(!noApp){
      sendCodeDialog()
    }
  }, []);

  return (
    <div className="data-block-sub">
      {/*<img src={userNotConfirmed} />*/}
      <div className="subtitle">
        {noApp ?
          translate('notifications.github_repos.github_app_required') :
          translate('notifications.github_user.user_not_confirmed')
        }
      </div>
      {noApp ?
        <Button
          variant="contained" color="primary" disabled={!isOwner}
          onClick={()=> {
            window.open(process.env.REACT_APP_GITHUB_APP_INSTALL_URL, iniFrame() ? '_blank' : '_self')
          }}
        >
          {translate('forms.github_add_edit_user.install_github_app')}
        </Button> :
        <Button variant="contained" color="primary" disabled={!isOwner} onClick={sendCodeDialog}>
          {translate('notifications.choices.confirm_user')}
        </Button>
      }
    </div>
  );
};

export default (UserNotConfirmedBlock);
