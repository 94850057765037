import {AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool,} from 'amazon-cognito-identity-js';
import {Auth} from 'aws-amplify';
import {
  CognitoIdentityProviderClient,
  GetUserCommand,
  VerifySoftwareTokenCommand,
  InitiateAuthCommand
} from '@aws-sdk/client-cognito-identity-provider';
import {CognitoIdentityClient} from '@aws-sdk/client-cognito-identity'
import {fromCognitoIdentityPool} from '@aws-sdk/credential-provider-cognito-identity';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import QR from 'qrcode';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import mfaIco from '../assets/images/mfa-icon.png';
import React from 'react';
import AuthCode from 'react-auth-code-input';
import {Button} from '../components/StyledComponents';
import {EErrorText, ELocalStoreKeys} from '../models/consts';
import moment from 'moment';
import {IUser} from "../models/models";
import {iniFrame} from "../serviceWorker";

const AWS_REGION = 'us-west-2';

let userPool: CognitoUserPool;

export const cognitoSetup = () => {
  if (
    process.env.REACT_APP_COGNITO_USER_POOL_ID &&
    process.env.REACT_APP_COGNITO_CLIENT_ID
  )
    userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

  Auth.configure({
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      region: AWS_REGION,
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'email',
        'openid',
        'aws.cognito.signin.user.admin',
        'phone',
        'profile',
      ],
      redirectSignIn: `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}/auth/login`,
      redirectSignOut: `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}/auth/login`,
      responseType: 'token',
    },
  });
};

const getTokens = (session) => {
  return {
    accessToken: session.getAccessToken().getJwtToken(),
    idToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
};

const getCognitoIdentityCredentials = (token) => {
  const loginInfo = {};
  loginInfo[`cognito-idp.${AWS_REGION}.amazonaws.com/${
    process.env.REACT_APP_COGNITO_USER_POOL_ID
  }`] = token;
  const params = {
    region: AWS_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID as string,
    logins: loginInfo,
    client: new CognitoIdentityClient({region: AWS_REGION})
  };
  return fromCognitoIdentityPool(params);
};

export const onSignUp = (userData) => {
  const attributeList: any[] = [];

  const fName = {
    Name: 'family_name',
    Value: userData.family_name,
  };

  const dataEmail = {
    Name: 'email',
    Value: userData.email,
  };
  const dataname = {
    Name: 'name',
    Value: userData.name,
  };
  const dataCreatedAt = {
    Name: 'custom:created_at',
    Value: moment().toISOString(),
  };

  const autoConfirm = {
    Name: 'custom:auto_confirm',
    Value: 'true',
  };
  if (iniFrame()) {
    attributeList.push(new CognitoUserAttribute(autoConfirm));
  }

  attributeList.push(new CognitoUserAttribute(dataEmail));
  attributeList.push(new CognitoUserAttribute(fName));
  attributeList.push(new CognitoUserAttribute(dataname));
  attributeList.push(new CognitoUserAttribute(dataCreatedAt));

  return new Promise((resolve, reject) => {
    userPool.signUp(
      userData.email,
      userData.password,
      attributeList,
      [],
      (err, result) => {
        if (err) {
          reject(err);
        }

        resolve(result);
      }
    );
  });
};

export const onForgotPassword = ({email}) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(email)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        if (err.message.includes(EErrorText.INVALID_LAMBDA)) {
          resolve(err);
        }
        reject(err);
      });
  });
};

export const onResendSignUp = (email) => {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(email)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        if (err.message.includes(EErrorText.INVALID_LAMBDA)) {
          resolve(err);
        }
        reject(err);
      });
  });
};

export const onPasswordConfirm = ({email, confirmation_code, new_password}) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(email, confirmation_code, new_password)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        if (err.message.includes(EErrorText.INVALID_LAMBDA)) {
          resolve(err);
        }
        reject(err);
      });
  });
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const onLogin = ({email, password}) => {
  return new Promise<string>((resolve, reject) => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const credentials = getCognitoIdentityCredentials(idToken);
        try {
          await credentials();
          resolve(idToken);
        } catch (error) {
          console.error('err1', error);
          reject(error);
        }
      },
      onFailure: (err) => {
        reject(err);
      },
      //for MFA
      totpRequired: async () => {
        // User needs to write a password from the app
        let token = '';
        let errMsg: string | React.JSX.Element  = '';
        const MySwal = withReactContent(Swal);
        await MySwal.fire({
          customClass: {
            container: 'mfa-login-container',
            popup: 'mfa-login-modal',
          },
          iconHtml: <img src={mfaIco} loading="lazy"/>,
          titleText: 'Login Two-Factor Authentication',
          html: (<form onSubmit={(e) => {
            e.preventDefault()
            MySwal.clickConfirm()
          }}>
            <div className='mfa-container'>
              <p>Enter the authentication code from Authenticator app</p>
              <AuthCode
                containerClassName='mfa-code-input'
                allowedCharacters='numeric'
                onChange={(e) => {
                  token = e
                  document.querySelector('.mfa-err')?.remove()
                }}
              />
              <div className='action-button-mfa'>
                <Button className="primary-button w-100" type='submit'>
                  Authenticate
                </Button>
                <Button className="primary-button outlined w-100" onClick={MySwal.clickDeny}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>),
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: true,
          showLoaderOnConfirm: true,
          showConfirmButton: false,
          preConfirm: async () => {
            MySwal.update({
              html: (<form onSubmit={(e) => {
                e.preventDefault()
                MySwal.clickConfirm()
              }}>
                <div className='mfa-container pad-b'>
                  <p>Enter the authentication code from Authenticator app</p>
                  <AuthCode
                    containerClassName='mfa-code-input'
                    allowedCharacters='numeric'
                    placeholder='∗'
                    onChange={() => console.log('dis')}
                    disabled={true}
                  />
                </div>
              </form>)
            })
            MySwal.showLoading()
            if (!token || token.length !== 6) {
              errMsg = 'Insert code!';
              token = '';
              MySwal.update({
                html: (<form onSubmit={(e) => {
                  e.preventDefault()
                  MySwal.clickConfirm()
                }}>
                  <div className='mfa-container'>
                    <p>Enter the authentication code from Authenticator app</p>
                    <AuthCode
                      containerClassName='mfa-code-input'
                      allowedCharacters='numeric'
                      onChange={(e) => {
                        token = e
                        document.querySelector('.mfa-err')?.remove()
                      }}
                    />
                    <div className='mfa-err'>{errMsg}</div>
                    <div className='action-button-mfa'>
                      <Button className="primary-button w-100" type='submit'>
                        Authenticate
                      </Button>
                      <Button className="primary-button outlined w-100" onClick={MySwal.clickDeny}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>)
              })
              return false
            }
            //if token exist we use
            cognitoUser.sendMFACode(
              token,
              {
                onSuccess: (result) => {
                  errMsg = 'Code accepted';
                  MySwal.update({
                    html: (<form onSubmit={(e) => {
                      e.preventDefault()
                      MySwal.clickConfirm()
                    }}>
                      <div className='mfa-container pad-b'>
                        <p>Enter the authentication code from Authenticator app</p>
                        <AuthCode
                          containerClassName='mfa-code-input'
                          allowedCharacters='numeric'
                          placeholder='∗'
                          onChange={() => console.log('dis')}
                          disabled={true}
                        />
                        <div className='mfa-err success-code'>{errMsg}</div>
                      </div>
                    </form>)
                  })
                  const idToken = result.getIdToken().getJwtToken();
                  const credentials = getCognitoIdentityCredentials(idToken);
                  credentials().then(() => {
                    MySwal.close()
                    resolve(idToken);
                  });
                },
                onFailure: (err) => {
                  let msg: string | React.JSX.Element = ''
                  let reload = false
                  if (err.message === "Code mismatch") {
                    msg = "Code is invalid, please check your authenticator"
                  } else if (err.message === "Invalid code received for user") {
                    msg = "Code is invalid, please check your authenticator"
                  } else if (err.message === "Invalid session for the user, session is expired.") {
                    reload = true;
                    // msg = "Code has expired, please check your authenticator"
                    msg = (<React.Fragment>
                        Login session has expired.
                        <br />
                        Reload and start again.
                      </React.Fragment>)
                  } else if (err.message === "Your software token has already been used once.") {
                    msg = "Please wait for your app to refresh a new code"
                  } else {
                    msg = err.message
                  }
                  errMsg = msg;
                  token = '';
                  MySwal.update({
                    html: (<form onSubmit={(e) => {
                      e.preventDefault()
                      MySwal.clickConfirm()
                    }}>
                      <div className='mfa-container'>
                        <p>Enter the authentication code from Authenticator app</p>
                        <AuthCode
                          containerClassName='mfa-code-input'
                          allowedCharacters='numeric'
                          onChange={(e) => {
                            token = e
                            document.querySelector('.mfa-err')?.remove()
                          }}
                          disabled={reload}
                        />
                        <div className='mfa-err'>{errMsg}</div>
                        <div className='action-button-mfa'>
                          {!reload && (
                            <Button className="primary-button w-100" type='submit'>
                              Authenticate
                            </Button>
                          )}
                          <Button className="primary-button outlined w-100" onClick={MySwal.clickDeny}>
                            {reload ? 'Reload' : 'Cancel'}
                          </Button>
                        </div>
                      </div>
                    </form>),
                  })
                  // MySwal.hideLoading()
                },
              },
              'SOFTWARE_TOKEN_MFA'
            )
            await delay(1500);
            return false
          }
        }).then((res) => {
          if (res.isDenied) {
            window.location.reload()
          }
          // if need to close
          // else {
          //   reject()
          // }
        })
      }
    });
  });
};

export const onGoogleLoginBtn = () => {
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });
};

export const onGoogleLogin = async (token: string) => {
  const credentials = getCognitoIdentityCredentials(token);
  try {
    await credentials();
    return token;
  } catch (error) {
    console.error('err1', error);
  }
};

export const onUpdatePassword = async ({oldPassword, password}) => {
  try {
    const user = await Auth.currentUserPoolUser();
    await Auth.changePassword(user, oldPassword, password);
  } catch (error) {
    console.error(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const currentUser = await Auth.currentUserInfo();
    return currentUser.attributes as IUser;
  } catch (error) {
    console.error(error);
  }
};

export const editUser = async (userData) => {
  try {
    const user = await Auth.currentUserPoolUser();
    await Auth.updateUserAttributes(user, {
      family_name: userData.lastName,
      name: userData.firstName,
    });
    const updatedUser = await Auth.currentAuthenticatedUser();
    return updatedUser.attributes as IUser;
  } catch (error) {
    console.error(error);
  }
};

export const onLogout = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error(error);
  }
};

export const checkTokenExpiration = async () => {
  const currentToken = localStorage.getItem(ELocalStoreKeys.TOKEN);
  try {
    const session = await Auth.currentSession();
    const cashedIdTokenCognito = session.getIdToken().getJwtToken();

    if (session.isValid() && currentToken && currentToken === cashedIdTokenCognito) {
      return currentToken;
    } else {
      const newSession = await Auth.currentSession();
      const newIdToken = newSession.getIdToken().getJwtToken();
      localStorage.setItem(ELocalStoreKeys.TOKEN, newIdToken);

      return newIdToken;
    }
  } catch (error) {
    console.log(error);
    throw {
      status: 401,
      message: 'Authorization Required',
      error: '401 Unauthorized Access',
    };
  }
};

export const getQr = async () => {
  const cognitoUser = await Auth.currentUserPoolUser();
  return new Promise<string>((resolve, reject) => {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: (secretCode: string) => {
        const name = `BackupLABS: ${cognitoUser.attributes.email}`;
        const uri = `otpauth://totp/${decodeURI(name)}?secret=${secretCode}&issuer=BackupLABS&image=https://bl-public-media.s3.amazonaws.com/bl-brandmark-gradient-transparent-01.png`;
        QR.toDataURL(uri, (err, result) => {
          if (err) reject(err);
          else resolve(result);
        });
      },
      onFailure: (err) => console.log(err),
    });
  });
};

export const validateMFAUserCode = async (userCode: string) => {
  try {
    const token = await checkTokenExpiration();
    const currentSession = await Auth.currentSession();
    const AccessToken = currentSession.getAccessToken().getJwtToken();
    if (!token) {
      return;
    }

    const cognito = new CognitoIdentityProviderClient({
      region: AWS_REGION,
      credentials: getCognitoIdentityCredentials(token),
    });

    const params = {
      AccessToken,
      UserCode: userCode,
    };
    const result = await cognito.send(new VerifySoftwareTokenCommand(params));
    return result;
  } catch (e) {
    // console.log(e)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw ({message: e.message})
  }
}

export const enableMFAForUser = async () => {
  const cognitoUser = await Auth.currentUserPoolUser()
  const settings = {
    PreferredMfa: true,
    Enabled: true,
  }
  cognitoUser.setUserMfaPreference(null, settings, (err, result) => {
    console.log(result)
  })

}


export const mfaEnabled = async () => {
  const token = await checkTokenExpiration();
  const currentSession = await Auth.currentSession();
  const AccessToken = currentSession.getAccessToken().getJwtToken();
  const cognito = new CognitoIdentityProviderClient({
    region: AWS_REGION,
    credentials: getCognitoIdentityCredentials(token),
  });

  const params = {
    AccessToken,
  };

  try {
    const data = await cognito.send(new GetUserCommand(params));
    return !!(data.UserMFASettingList &&
      data.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA'));
  } catch (e) {
    return false;
  }
};

export const disableMFACognito = async () => {
  try {
    const cognitoUser = await Auth.currentUserPoolUser()
    const settings = {
      PreferredMfa: false,
      Enabled: false
    }
    await cognitoUser.setUserMfaPreference(null, settings, (err, result) => {
      console.log(result)
    })
    return true
  } catch (e) {
    console.log(e)
  }
}
