import {CircularProgress, Dialog, DialogContent, DialogTitle} from '@mui/material';
import React, {FunctionComponent, memo, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IError, ITwoFAProps} from '../../../../../models/inner-models';
import './style.scss';
import {Button} from "../../../../../components/StyledComponents";
import {disableMFACognito, enableMFAForUser, validateMFAUserCode} from "../../../../../cognito/cognitoTest";
import AuthCode from "react-auth-code-input";
import appStore from '../../../../../assets/icons/app-store.svg'
import playStore from '../../../../../assets/icons/play-store.svg'
import mfaIco from "../../../../../assets/images/mfa-icon.png";
import Swal from "sweetalert2";
import {closeModalIcon, iconNotifyAlert} from "../../../../../assets/icons/icons";
const TwoFAModal: FunctionComponent<ITwoFAProps> = ({
                                                      isOpen,
                                                      closeNotification,
                                                      imgSrc,
                                                      setImgSrc,
                                                      isEnable,
                                                      setIsEnable,
                                                      showToast
                                                    }: ITwoFAProps) => {
  const {t: translate} = useTranslation();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [userCode, setUserCode] = useState<string>('')
  const [errCode, setErrCode] = useState<string>('')
  const [inpKey, setInpKey] = useState<number>(0)

  const handleClose = () => {
    setUserCode('')
    setErrCode('')
    closeNotification && closeNotification();
  }

  useEffect(()=>{
    setUserCode('')
    setErrCode('')
  }, [isOpen])

  const enableMFA = async () => {
    try {
      setIsProcessing(true)
      const result = await validateMFAUserCode(userCode)
      if (!result) {
        return
      }
      if (result.Status && result.Status === 'SUCCESS') {
        await enableMFAForUser()
        setIsEnable(true)
        setImgSrc('')
        setUserCode('')
        handleClose()
        showToast()
      } else {
        console.log(result)
      }
      setIsProcessing(false)
    } catch (e) {
      setUserCode('')
      setInpKey(Math.random())
      const error = e as IError;
      let msg = ''
      if (error.message === "Code mismatch") {
        msg = "Code is invalid, please check your authenticator"
      } else if (error.message === "Invalid code received for user") {
        msg = "Code is invalid, please check your authenticator"
      } else if (error.message === "Invalid session for the user, session is expired.") {
        msg = "Code has expired, please check your authenticator"
      } else if (error.message === "Your software token has already been used once.") {
        msg = "Please wait for your app to refresh a new code"
      } else {
        msg = error.message
      }
      setErrCode(msg)
      console.log(error.message)
      setIsProcessing(false)
    }
  }

  const disableMFA = async () => {
    try {
      setIsProcessing(true)
      const result = await validateMFAUserCode(userCode)
      if (!result) {
        return
      }
      if (result.Status && result.Status === 'SUCCESS') {
        await disableMFACognito()
        setImgSrc('')
        setIsEnable(false)
        setUserCode('')
        handleClose()
        Swal.fire({
          title: 'Warning',
          text: 'You have disabled 2FA from your account. Please remove it from your authenticator app. A new QR code will issued should you re-enable.',
          imageUrl: iconNotifyAlert,
          confirmButtonText: 'I understand',
          showConfirmButton: true,
          allowOutsideClick:false
        })
          .then(()=>{
            showToast()
          });
      } else {
        console.log(result)
      }
      setIsProcessing(false)
    } catch (e) {
      setInpKey(Math.random())
      setUserCode('')
      const error = e as IError;
      let msg = ''
      if (error.message === "Code mismatch") {
        msg = "Code is invalid, please check your authenticator"
      } else if (error.message === "Invalid code received for user") {
        msg = "Code is invalid, please check your authenticator"
      } else if (error.message === "Invalid session for the user, session is expired.") {
        msg = "Code has expired, please check your authenticator"
      } else if (error.message === "Your software token has already been used once.") {
        msg = "Please wait for your app to refresh a new code"
      } else {
        msg = error.message
      }
      setErrCode(msg)
      console.log(error.message)
      setIsProcessing(false)
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="mfa-edit-title"
        aria-describedby="mfa-edit-description"
        classes={{
          root: 'mfa-container',
          paper: 'mfa-modal',
        }}
      >
        <DialogTitle id="mfa-edit-title">
          <img src={mfaIco} loading="lazy"/>
          {isEnable ? 'Disable Two-Factor Authentication' : 'Enable Two-Factor Authentication'}
        </DialogTitle>

        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>

        <DialogContent>
          <form  onSubmit={isEnable ? disableMFA : enableMFA}>
          <div id="mfa-edit-description">
            <div className={!isEnable ? "username-block" : "username-block dis-tex"}>
              <div className='mfa-add-select-all'>
                {!isEnable && (
                  <>
                    <div className='title-line'>
                      Enabling 2FA will require you to enter a unique code when you login
                    </div>
                    <div className='desc-line'>
                      {/*We`ll text you a verification code to this app whenever you sign in to your account.*/}
                      {/*<br/>*/}
                      Download an authenticator for:
                      <br/>
                      <img
                        loading="lazy"
                        className='mfa-store-ico'
                        src={playStore}
                        onClick={() => window.open('https://play.google.com/store/search?q=authenticator&c=apps', '_blank')}
                      />
                      <img
                        loading="lazy"
                        className='mfa-store-ico'
                        src={appStore}
                        onClick={() => window.open('https://apps.apple.com/', '_blank')}
                      />
                    </div>
                  </>
                )}
                {isEnable && (
                  <>
                    <div className='title-line'>
                      To disable 2FA, enter the code from your Authenticator app
                    </div>
                    <div className='desc-line'/>
                  </>
                )}
              </div>
            </div>

            <div className="scrollable-div">
              {!isEnable && (
                <div className='mfa-how-to'>
                  <img src={imgSrc} loading="lazy"/>
                  <div className='mfa-how-to-step'>
                    <span>1. Please scan this QR code using your app</span>
                    <span>2. Enter the code provided</span>
                    <span>3. Select Enable</span>
                  </div>
                </div>
              )}
              <p className='code-input'>Enter code</p>
                <AuthCode
                  key={inpKey}
                  containerClassName='mfa-code-input'
                  allowedCharacters='numeric'
                  onChange={(event) => {
                    setUserCode(event)
                    setErrCode('')
                  }}
                  disabled={isProcessing}
                />
              {!!errCode.length && (
                <label className="invalid-code">{errCode}</label>
              )}
            </div>
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              disabled={isProcessing}
            >
              {translate('notifications.choices.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={isEnable ? disableMFA : enableMFA}
              disabled={isProcessing || userCode.length < 6 || userCode.length > 7}
              type='submit'
            >
              {isProcessing && (
                <div className="small-spinner-wrapper">
                  <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '6px'}}/>
                </div>
              )}
              {isEnable ? 'Disable Two-Factor Authentication' : 'Enable Two-Factor Authentication'}
            </Button>
          </div>
          </form>

        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(TwoFAModal);
