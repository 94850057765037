import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import PlatformActionsPage from '../../../../components/PlatformComponents/PlatformActionsPage';
import { EPaymentRequiredType, EPlatformsNames, EStripeSubStatus, ETrialStatus } from '../../../../models/consts';
import {IError, IPaymentRequiredPoppup} from '../../../../models/inner-models';
import { downloadTrelloZip, getTrelloActions, restoreTrelloBackup } from '../../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearBackupsState } from '../../../../store/reducers/backups';
import {
  getTrelloActionsSelector, getTrelloActionsTotalSelector, getTrelloCanRestore, isSubUser,
  isTrelloActionsLoadingSelector, roleByApp, trelloSubscription,
  trelloTrialStatus,
} from '../../../../store/selectors';
import restore from '../../../../utils/functions/restore';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import { IBackupRow } from '../../types';
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import {errorBigIcon, iconNotifySuccess} from "../../../../assets/icons/icons";


const TrelloActivityPage: FunctionComponent = () => {
  const platformName = EPlatformsNames.TRELLO;

  const { t: translate } = useTranslation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const id = searchParams.get('id');

  const actions = useAppSelector(getTrelloActionsSelector);
  const actionsTotal = useAppSelector(getTrelloActionsTotalSelector);
  const loading = useAppSelector(isTrelloActionsLoadingSelector);
  const canRestore = useAppSelector(getTrelloCanRestore);
  const trialState = useAppSelector(trelloTrialStatus);
  const subInfo = useAppSelector(trelloSubscription);
  const isUserSub = useAppSelector(isSubUser);
  const roleApp = useAppSelector(roleByApp);
  const isAdminRole = isUserSub && roleApp?.trello === 'admin' && (trialState === 'active' || subInfo?.planName === 'Pro') || !isUserSub

  const [rows, setRows] = useState<Array<IBackupRow> | null>(null);
  const [paymentRequiredState, setPaymentRequiredState] = useState<EPaymentRequiredType>(EPaymentRequiredType.NON);

  const [paymentPoppup, setPaymentPoppup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName: platformName,
  });
  const [restartFetch, setRestartFetch] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (actionsTotal && actionsTotal !== total) {
      setTotal(actionsTotal)
    }
  }, [actionsTotal])

  useEffect(() => {
    setPaymentRequiredState(
      subInfo?.status === EStripeSubStatus.ACTIVE || trialState === ETrialStatus.STARTED ?
        EPaymentRequiredType.NON :
        !subInfo ? EPaymentRequiredType.TRIAL : EPaymentRequiredType.SUB,
    );
  }, [trialState, subInfo]);

  const closeTrialPoppup = useCallback(() => {
    setPaymentPoppup({ ...paymentPoppup, isOpen: false });
  }, []);

  const restoreItem = (versions: string, name: string, key: string, emails: string[], organizationId:string) => {
    if (paymentRequiredState === EPaymentRequiredType.NON) {
      return restore(() => onConfirmRestore(versions, name, key, emails, organizationId), translate);
    } else {
      if (!isUserSub) {
        setPaymentPoppup((prev) => ({
          ...prev,
          isOpen: true,
        }));
      }
    }
  };

  const onConfirmRestore = async (versionId:string, name:string , boardId:string, emails:string[], organizationId?:string) => {
    if (versionId && boardId) {
      return dispatch(restoreTrelloBackup({items: [{id: boardId, versions: versionId, name: name,organizationId}], emails})).unwrap();
    }
  };

  const downloadZipCallback = useCallback(({versions, key}) => {
    dispatch(downloadTrelloZip({ versions: versions, boardId: key }))
      .unwrap()
      .then((res) => {
        window.open(`${res.url}`, '_blank');
      })
      .catch((err) => {
        console.log(err);
        const error = err as IError;

        console.log(error);
        if (error.status === 'success') {
          setRestartFetch(prevState => prevState + 1)
          Swal.fire({
            title: translate('notifications.titles.success'),
            text: decoratePopUpMessage(`${error.message as string}. The backup archive preparation can take a few minutes. We will send you an email once it is ready and available to download.`),
            imageUrl: iconNotifySuccess,
          });
        } else {
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.message as string),
            imageUrl: errorBigIcon,
          });
        }
      });
  }, []);

  useEffect(() => {
    !loading && actions && setRows(actions?.map((props, index) => ({ ...props, id: index })));
  }, [actions, loading]);

  useEffect(() => {
    if (!id) {
      navigate('/')
      return;
    }

    dispatch(clearBackupsState());
    updList()
  }, [id]);

  const updList = async (newPage?: number) => {
    try {
      if (!id) {
        return;
      }
      setIsLoading(true)
      await dispatch(getTrelloActions({itemId: id, perPage: 10, page: newPage || 0}))
        .unwrap()
        .catch((err) => {
          console.log(err);
          const error = err as IError;
          setIsLoading(false)

          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.error as string),
            imageUrl: errorBigIcon,
          })
            .then(() => {
              navigate('/platform/dashboard/trello')
            });
        });
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e);
    }
  }

  return (
    <PlatformActionsPage
      platformName={platformName}
      rows={rows || []}
      downloadZipCallback={downloadZipCallback}
      restoreItemCallback={restoreItem}
      closeTrialPopupCallback={closeTrialPoppup}
      loading={loading}
      total={total}
      page={page}
      setPage={(p)=>{
        setPage(p)
        updList(p)
      }}
      isLoading={isLoading}
      paymentPopup={paymentPoppup}
      paymentRequiredState={paymentRequiredState}
      canRestore={canRestore}
      isAdmin={isAdminRole}
    />
  );
};

export default withRedirectAfterLogout()(TrelloActivityPage);
